import axios from 'axios'

const API = 'cursos/gestionar'

const ENDPOINTS = {
    crearCurso(id_carpeta, model){
        return axios.post(`${API}/${id_carpeta}/crear`,model)
    },
    infoBasica(id_curso){
        return axios(`${API}/${id_curso}/info-basica`)
    },
    almacenarRecursos(id_curso,model){
        return axios.post(`${API}/${id_curso}/almacenar-recursos`,model)
    },
    getRecursos(id_curso){
        return axios(`${API}/${id_curso}/recursos`)
    },
    eliminarRecurso(id_recurso){
        return axios.delete(`${API}/recursos/${id_recurso}/delete`)
    }, 
    updateInfoBasico(id_curso,model){
        return axios.put(`${API}/${id_curso}/basicos`,model)
    },
    updateTipo(id_curso,model){
        return axios.put(`${API}/${id_curso}/update-tipo`,model)
    },
    listarEntregas(id_curso){
        return axios.get(`${API}/${id_curso}/listar/entregas`)
    },
    crearEntrega(id_curso,model){
        return axios.post(`${API}/${id_curso}/entregas`,model)
    },
    updatePosicionEntrega(id_curso,model){
        return axios.put(`${API}/${id_curso}/entregas/posicion`,model)
    },
    eliminarEntrega(id_entrega){
        return axios.delete(`${API}/entregas/${id_entrega}/delete`)
    },
    crearTema(id_curso,model){
        return axios.post(`${API}/${id_curso}/temas`,model)
    },
    updateTema(id_tema,model){
        return axios.put(`${API}/temas/${id_tema}/edit`,model)
    },
    listarTemas(id_curso){
        return axios(`${API}/${id_curso}/listar/temas`)
    },
    updatePosicionTema(id_curso,model){
        return axios.put(`${API}/${id_curso}/temas/posicion`,model)
    },
    eliminarTema(id_tema){
        return axios.delete(`${API}/temas/${id_tema}/delete`)
    },
    infoTema(id_tema){
        return axios(`${API}/temas/${id_tema}/info-tema`)
    },
    almacenarRecursosTemas(id_tema,model){
        return axios.post(`${API}/temas/${id_tema}/recursos`,model)
    },
    updateDescarga(id_recurso_tema){
        return axios.post(`${API}/temas/recursos/${id_recurso_tema}/descargable`)
    },
    updatePosicionRecursoTema(id_tema,model){
        return axios.put(`${API}/temas/${id_tema}/recursos/posicion`,model)
    },
    deleteRecursoTema(id_recurso_tema){
        return axios.delete(`${API}/temas/recursos/${id_recurso_tema}/delete`)
    },
    selectUsuarios(id_curso,tipo){
        return axios(`${API}/${id_curso}/usuarios?tipo=${tipo}`)
    }, 
    listarLectores(id_curso){
        return axios(`${API}/${id_curso}/lectores`)
    },
    agregarLector(id_curso,id_user){
        return axios.post(`${API}/${id_curso}/lectores/agregar/${id_user}`)
    },
    removerLector(id_curso,id_user){
        return axios.delete(`${API}/${id_curso}/lectores/remover/${id_user}`)
    },

    listarResponsables(id_curso){
        return axios(`${API}/${id_curso}/responsables`)
    },
    agregarResponsable(id_curso,id_user){
        return axios.post(`${API}/${id_curso}/responsables/agregar/${id_user}`)
    },
    removerResponsable(id_curso,id_user){
        return axios.delete(`${API}/${id_curso}/responsables/remover/${id_user}`)
    },
    guardarLectores(id_curso, model){
        return axios.post(`${API}/${id_curso}/lectores/agregar`, model)
    }
};

export default ENDPOINTS;