import { render, staticRenderFns } from "./modalCrearCurso.vue?vue&type=template&id=b3adda92&scoped=true&"
import script from "./modalCrearCurso.vue?vue&type=script&lang=js&"
export * from "./modalCrearCurso.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3adda92",
  null
  
)

export default component.exports