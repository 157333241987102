<template>
    <modal ref="modalCrearCurso" titulo="Crear Curso" tamano="modal-lg" :no-aceptar="true" adicional="Crear Curso" @adicional="crearCurso" :desactivar-guardar="false">
        <ValidationObserver ref="form1">
            <div class="row mx-5">
                <div class="col">
                    <ValidationProvider v-slot="{ errors }" rules="required" name="titulo">
                        <el-input
                        v-model="model.nombre"
                        class="w-100 input-name mt-4"
                        placeholder="Titulo del curso"
                        maxlength="120"
                        show-word-limit 
                        />
                        <span class="v-error">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <p class="f-15 text-gris mt-3 mb-2 pl-3">
                        Descripcion del curso
                    </p>
                    <ValidationProvider v-slot="{ errors }" rules="required" name="descripcion">
                        <el-input 
                        v-model="model.descripcion" 
                        placeholder="Por favor digete la descripción..." 
                        type="textarea" :rows="10"
                        maxlength="2000"
                        show-word-limit
                        class="w-100 area-radius" 
                        />
                        <span class="v-error">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <div class="row mx-0">
                    <div class="col-12 f-15 mt-3">
                        Seleccione el orden en el que se deberán desarrollar los temas <br /> 
                    </div>
                    <div class="col-12 mt-3">
                        <span class="f-600">Abierto:</span> El lector podrá acceder a todos los temas sin un orden específico. <br />
                        <span class="f-600">Progresivo:</span> El lector accederá a los temas solo en el orden establecido.
                    </div>
                </div>
                <div class="row mx-0 mt-4 pb-3">
                    <div class="col-auto">
                        <el-radio v-model="model.tipo" :label="1">
                            <span class="f-18">
                                Abierto
                            </span>
                        </el-radio>
                    </div>
                    <div class="col-auto">
                        <el-radio v-model="model.tipo" :label="2">
                            <span class="f-18">
                                Progresivo
                            </span>
                        </el-radio>                     
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import { Alert } from 'bootstrap'
import Cursos from '~/services/cursos/gestionar'
export default {
    data(){
        return {
            model: {
                nombre: '',
                descripcion: '',
                tipo:1
            },
            hacerEmit: false
            
        }
    },
    computed:{
        id_carpeta(){
            return Number(this.$route.params.id_carpeta)
        }
    },
    methods: {
        toggle(hacerEmit=false){
            this.limpiar()
            this.hacerEmit = hacerEmit
            this.$refs.modalCrearCurso.toggle();
        },
        async crearCurso(){
            try {
                const valid = this.$refs.form1.validate()

                if(valid){
                    const {data} = await Cursos.crearCurso(this.id_carpeta, this.model)
                    this.$refs.modalCrearCurso.toggle();
                    this.notify('Éxito, Curso agregado correctamnete','success')
                    if(this.hacerEmit){
                        this.$emit('actualizar')
                    }else{
                        this.$router.push({name:'mis-cursos.editar.curso',params:{id_curso:data.id}})
                    }
                }

              

            } catch (error){
                this.error_catch(error)
            }
        },
        limpiar(){
            this.model = {
                nombre:'',
                descripcion:'',
                tipo:1
            }
            this.$refs.form1.reset()
        }
    }
}
</script>

<style lang="scss" scoped>
</style>